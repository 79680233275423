/**
 * Determine whether to use dark or light text on top of given color.
 * Returns black for dark text and white for light text.
 */
/**
 * Calculate the contrast ratio between two colors.
 * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 */
/**
 * Calculate the luminance for a color.
 * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 */
/* Base */
/* Ads */
/* Header */
/* Article */
/* Deals */
/* Detective */
/* Drupal */
/* Panel */
/* Subscribe */
/* Tag */
/* Taxonomy */
[data-color-scheme=dark] {
  --color-brand-primary: #152e42;
  --color-brand-primary-text: #ffffff;
  --color-brand-secondary: #d72439;
  --color-brand-secondary-text: #ffffff;
  --color-brand-tertiary: #1d1e1c;
  --color-brand-tertiary-text: #ffffff;
  --container-width: 1200px;
  --container-padding: 15px;
  --sidebar-width: 300px;
  --section-max-width: 728px;
  --section-wide-max-width: 100vw;
  --section-wide-desktop-max-width: 830px;
  --section-wide-desktop-wide-max-width: 1100px;
  --scrollbar-width: 0;
  --body-background-color: #000;
  --body-font-size: 1.6em;
  --body-header-background-color: rgba(255, 255, 255, 0.1);
  --body-line-height: 1.4;
  --body-text-color: #ccc;
  --body-text-diminished-color: #888;
  --body-heading-text-color: #fafafa;
  --body-grippie-color: #333;
  --color-brand-sponsored: #38b124;
  --link-text-color: #d72439;
  --link-text-color--hover: inherit;
  --link-underline-color: #d72439;
  --link-underline-color--hover: inherit;
  --link-underline-line: underline;
  --link-underline-offset: 2px;
  --link-underline-thickness: 2px;
  --header-top-text-color: white;
  --header-top-background-color--scrolled: rgba(40, 40, 40, 0.75);
  --header-top-alert-bar-background-color: #152e42;
  --header-top-alert-bar-text-color: white;
  --header-top-brand-image-url: url(../../../../thejourney.co/images/tj-logo-wide--light.svg);
  --header-top-brand-image-url--title: url(../../../../thejourney.co/images/tj-logo-wide--light.svg);
  --header-top-brand-image-url--title-alt: url(../../../../thejourney.co/images/tj-logo-wide--light.svg);
  --header-top-brand-vpn-image-url: url(../assets/images/vpn-buyers-guide-logo--light.svg);
  --header-top-drawer-toggle-background-color: #152e42;
  --header-top-drawer-toggle-text-color: white;
  --header-top-drawer-toggle-width: unset;
  --header-top-height: 40px;
  --header-top-height--tablet: 60px;
  --header-top-height--scrolled: 40px;
  --header-top-height--tablet-scrolled: 60px;
  --header-top-navigation-item-background-color--hover: #152e42;
  --header-top-navigation-item-text-color: white;
  --header-top-navigation-item-text-color--hover: white;
  --header-top-navigation-item-text-color--search: white;
  --header-top-navigation-item-text-color--search-hover: white;
  --header-top-navigation-item-background-color--search-visible: #152e42;
  --header-top-navigation-item-text-color--search-visible: white;
  --header-top-navigation-search-form-background-color: #152e42;
  --header-top-navigation-search-form-text-color: #ffffff;
  --header-top-bottom-border: none;
  --header-top-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  --header-top-brand-image-url--sponsored: url(../../../../thejourney.co/images/tj-logo-sponsored--light.png);
  --drupal-node-background-image-url--unpublished: url(../assets/images/draft.svg);
  --block-topic-height--tablet: 60px;
  --block-topic-text-color: #ccc;
  --block-topic-input-text-color: #ccc;
  --block-topic-topics-sub-list-backdrop-filter: brightness(0.25) blur(15px);
  --block-header-text-color: #fafafa;
  --block-header-text-color--hover: #d72439;
  --block-header-border-bottom: 3px solid rgba(255, 255, 255, 0.15);
  --bullet-image-border-radius: 5px;
  --bullet-title-text-color: #fafafa;
  --bullet-title-text-color--hover: #ccc;
  --bullet-analysis-author-text-color: #fafafa;
  --bullet-tag-color: #d72439;
  --bullet-tag-text-color--cover-tablet: #d72439;
  --article-header-accent-color: #d72439;
  --article-header-accent-width: 2px;
  --article-header-author-avatar-size: 45px;
  --article-header-author-text-color: inherit;
  --article-header-breadcrumbs-font-family: le-monde-livre-book, Palatino, Times, serif;
  --article-header-breadcrumbs-text-color: #d72439;
  --article-header-details-text-color: inherit;
  --article-header-intro-text-color: #676767;
  --header-link-text-color: #d72439;
  --article-header-tag-letter-spacing: 0px;
  --article-header-tag-text-color: article-header-tag-text-color;
  --article-header-time-text-color: #d72439;
  --article-body-heading-emphasis-text-color: #969696;
  --article-body-font-size: clamp(16px, 4vw, 18px);
  --article-body-font-size--smaller: 16px;
  --article-body-font-size--smallest: 14px;
  --article-body-line-height: 1.55;
  --article-body-line-height--compact: 1.21;
  --article-body-bottom-margin: 25px;
  --article-body-paragraph-text-color--update: #969696;
  --article-body-paragraph-text-color--disclaimer: inherit;
  --disclaimer-font-size: var(--article-body-font-size--smaller);
  --caption-text-color: inherit;
  --jumplist-highlight-color: #d72439;
  --article-body-link-text-color: #d72439;
  --article-body-link-text-color--hover: #fff;
  --article-body-link-underline-color: #d72439;
  --article-body-link-underline-offset: 2px;
  --article-body-link-underline-thickness: 2px;
  --article-update-border-color: hsl(30deg 59% 84% / 25%);
  --article-update-border-radius: 10px;
  --article-update-border-width: 0px;
  --article-update-strong-color: #d72439;
  --article-update-bg-color: hsl(30deg 59% 84% / 15%);
  --update-font-size: var(--article-body-font-size--smaller);
  --update-padding-horizontal: clamp(15px, 2vw, 25px);
  --update-padding-vertical: 15px;
  --body-container-background-color: rgba(205, 205, 205, 0.1);
  --body-container-background-color--solid: #151515;
  --body-container-padding-bottom: 30px;
  --body-container-padding-sides: 30px;
  --body-container-padding-top: 30px;
  --body-container-border-color: rgba(150, 150, 150, 0.2);
  --body-container-border-radius: 10px;
  --body-container-border-width: 0px;
  --quote-pull-border-radius: 0px;
  --quote-pull-border-width: 0px;
  --quote-pull-padding: 0px;
  --quote-pull-padding--left: 0px;
  --quote-pull-padding--right: 0px;
  --quote-pull-font-size: clamp(28px, 3vw, 32px);
  --quote-pull-font-size--embellish: 22px;
  --quote-pull-line-height: 1.3;
  --article-body-blockquote-highlight-color: #152e42;
  --article-body-blockquote-text-color: #aaa;
  --article-body-blockquote-text-color--pull: #fff;
  --article-body-blockquote-icon-text-color: #fafafa;
  --article-body-blockquote-text-decoration-offset: calc(var(--article-body-blockquote-text-decoration-thickness) * -0.4);
  --article-body-blockquote-text-decoration-offset--embellish: calc(var(--article-body-blockquote-text-decoration-thickness--embellish) * -0.4);
  --article-body-blockquote-text-decoration-line: underline;
  --article-body-blockquote-text-decoration-thickness: 15px;
  --article-body-blockquote-text-decoration-thickness--embellish: 12px;
  --article-body-forum-embed-time-text-color: #666;
  --article-body-forum-embed-reply-text-color: #666;
  --article-body-tabs-container-border-color: #fff;
  --article-body-tabs-label-text-color--active: #fff;
  --article-body-good-bad-list-item-bullet-text-color: #fafafa;
  --good-bad-list-icon-top-offset: 0px;
  --article-body-list-item-bullet-text-color: #fafafa;
  --cta-background-color: #d72439;
  --cta-background-color--hover: #152e42;
  --cta-border: none;
  --cta-border-radius: 4px;
  --cta-font-size: 16px;
  --cta-line-height: 1.4;
  --cta-padding-bottom: 7.5px;
  --cta-padding-sides: 40px;
  --cta-padding-top: 8.5px;
  --cta-padding-bottom--large: 9px;
  --cta-padding-sides--large: 80px;
  --cta-padding-top--large: 11px;
  --cta-text-color: #ffffff;
  --cta-text-color--hover: #ffffff;
  --article-body-media-border-radius: 10px;
  --article-body-media-border-radius--tablet: 10px;
  --gallery-border-radius: 10px;
  --gallery-gap: 10px;
  --shop-bar-background-color: rgba(45, 45, 45, 0.75);
  --shop-bar-border-radius: min(10px, var(--cta-border-radius));
  --shop-bar-logo-image-url: url(../../../../thejourney.co/images/tj-logo-small--light.svg);
  --shop-bar-logo-aspect-ratio: 1.25;
  --shop-bar-item-category-background-color: rgba(150, 150, 150, 0.25);
  --shop-bar-item-category-text-color: #ccc;
  --shop-bar-item-category-background-color--first: #d72439;
  --shop-bar-item-category-text-color--first: #ffffff;
  --shop-bar-item-link-border-radius: 2px;
  --shop-bar-item-product-text-color: #fafafa;
  --article-body-reco-block-collection-amplify-border-radius: 15px;
  --article-body-reco-block-collection-product-badge-background-color: #152e42;
  --article-body-reco-block-collection-product-badge-text-color: #ffffff;
  --article-body-reco-block-collection-image-background-color: #fff;
  --article-body-reco-block-collection-image-border-radius: var(--article-body-reco-block-image-border-radius);
  --article-body-reco-block-collection-product-text-color: #d72439;
  --article-body-reco-block-collection-shop-link-border-radius: 3px;
  --reco-block-button-font-size: 14px;
  --versus-column-gap: 50px;
  --article-body-reco-block-versus-h4-a-color: inherit;
  --article-body-reco-block-versus-h4-a-color--hover: #d72439;
  --article-body-reco-block-versus-h4-a-border-width: 0px;
  --article-reco-block-versus-product-name-text-color: #d72439;
  --versus-dot-bg: #d72439;
  --versus-dot-border-color: #333;
  --versus-dot-border-radius: 50%;
  --versus-dot-padding: 3px 0 0;
  --versus-dot-text-color: #ffffff;
  --quick-shop-reco-block-border-color: rgba(150, 150, 150, 0.2);
  --quick-shop-reco-block-border-width: 1px;
  --quick-shop-product-text-color: inherit;
  --quick-shop-subtitle-text-color: inherit;
  --quick-shop-button-border-color: #d72439;
  --quick-shop-button-text-color: #d72439;
  --quick-shop-button-font-family: proxima-nova-regular, Helvetica, Arial, sans-serif;
  --rating-background-color: rgba(170, 170, 170, 0.15);
  --rating-full-border-radius: 5px;
  --rating-full-score-color: #fff;
  --rating-full-score-vertical-offset: 10px;
  --rating-mini-border-radius: 10px;
  --rating-star-color-filter: invert(69%) sepia(51%) saturate(515%) hue-rotate(360deg) brightness(103%) contrast(91%);
  --rating-star-empty-filter: invert(0.66) opacity(0.33);
  --reco-list-product-text-color: #d72439;
  --reco-list-product-underline-color: #d72439;
  --article-comments-comment-content-text-color: #ccc;
  --article-comments-comment-time-text-color: #666;
  --article-comments-comment-background-color--unpublished: #502828;
  --article-comments-comment-form-form-actions-text-color: #fafafa;
  --article-comments-comment-button-reply-text-color: #ccc;
  --author-signature-text-color: #d72439;
  --article-bio-border-color: #fff;
  --article-bio-bio-text-color: #969696;
  --article-bio-bio-link-text-color: #a0a0a0;
  --article-bio-name-text-color: #a0a0a0;
  --best-picks-award-font-family: proxima-nova-regular, Helvetica, Arial, sans-serif;
  --best-picks-award-font-size: 10px;
  --best-picks-award-frame-image-url: url(../../../../thejourney.co/images/tj-badge-frame.svg);
  --best-picks-award-highlight-text-color: #152e42;
  --best-picks-award-site-font-size: 7px;
  --best-picks-award-site-name-text-color: #000;
  --best-picks-award-text-color: #000;
  --article-body-reco-block-product-text-color: #d72439;
  --article-body-reco-block-cta-background-color--hover: #152e42;
  --article-body-reco-block-cta-text-color--hover: #ffffff;
  --article-body-reco-block-border-color: rgba(200, 200, 200, 0.5);
  --article-body-reco-block-border-color--featured: #1A56F2;
  --article-body-reco-block-border-radius: 15px;
  --article-body-reco-block-border-radius--slim: 10px;
  --article-body-reco-block-border-width: 4px;
  --article-body-reco-block-border-width--slim: 2px;
  --article-body-reco-block-padding-top: 30px;
  --article-body-reco-block-padding-sides: 30px;
  --article-body-reco-block-padding-bottom: 30px;
  --article-body-reco-block-padding--compact: 20px;
  --article-body-reco-block-category-background-color: #1A56F2;
  --article-body-reco-block-category-text-color: #ffffff;
  --article-body-reco-block-category-font-size: 16px;
  --article-body-reco-block-category-padding-top: 12px;
  --article-body-reco-block-category-padding-sides: 20px;
  --article-body-reco-block-category-padding-bottom: 10px;
  --article-body-reco-block-category-border-radius: 8px;
  --reco-block-subtitle-font-size: 18px;
  --reco-block-compact-button-padding: 5px 15px;
  --reco-block-image-background-color: #fbfbfb;
  --article-body-reco-block-image-border-radius: 10px;
  --article-body-reco-block-image-border-radius--compact: 5px;
  --article-body-reco-block-image-mix-blend-mode: darken;
  --article-credit-font-size: var(--article-body-font-size--smaller);
  --affiliate-disclosure-opacity: 0.5;
  --article-nav-menu-width: 180px;
  --article-nav-menu-width--narrow: 150px;
  --alert-background-color: rgba(255, 255, 0, 0.6);
  --alert-text-color: #fff;
  --best-answer-background-color: rgba(57, 174, 198, 0.1);
  --best-answer-border-color: rgba(57, 174, 198, 0.2);
  --best-answer-border-width: 0px;
  --body-backdrop-filter: blur(15px) brightness(.33) saturate(3);
  --checklist-container-background-color: hsl(202deg 47% 69% / 15%);
  --checklist-container-border-color: hsl(202deg 47% 69% / 25%);
  --checklist-container-border-width: 0px;
  --checklist-heading-font-size: clamp(16px, 4vw, 18px);
  --checklist-font-size: 16px;
  --checklist-heading-color: #fff;
  --checklist-icon-background-color: #fff;
  --dark-png-background: rgba(205, 205, 205, 0.5);
  --dark-png-padding: 20px;
  --drawer-icon-filter: invert(1);
  --drawer-brand-image-url: url("../../../../thejourney.co/images/tj-logo-wide--light.svg");
  --embellish-icon-font-size: 140px;
  --footnote-font-size: var(--article-body-font-size--smaller);
  --header-logo-aspect-ratio: 6.72;
  --header-logo-vertical-margin: 7px;
  --header-logo-vertical-margin--tablet: 13px;
  --hr-thickness: 1px;
  --icon-border-radius: 50%;
  --icon-button-filter: invert(1);
  --icon-button-filter--hover: invert(1);
  --icon-check-outline-filter: invert(1) hue-rotate(180deg) brightness(4.5) saturate(2);
  --icon-check-background-color: rgba(0, 209, 0, 0.4);
  --icon-minus-background-color: #d85401;
  --icon-minus-outline-filter: none;
  --icon-plus-background-color: #30CE8B;
  --icon-plus-outline-filter: none;
  --icon-star: url(../assets/images/icons/icon-star.svg);
  --icon-x-background-color: #ffd3d3;
  --icon-x-outline-filter: invert(1) hue-rotate(180deg) brightness(2.25) saturate(1.5);
  --panel-heading-text-color: #fafafa;
  --panel-heading-text-color--info: #d72439;
  --panel-heading-bar-color--info: #d72439;
  --panel-info-background-color: rgba(215, 36, 57, 0.1);
  --panel-info-border-color: rgba(215, 36, 57, 0.2);
  --panel-info-border-width: 0px;
  --newsletter-font-size: var(--article-body-font-size--smaller);
  --newsletter-background: rgba(0, 208, 211, 0.33);
  --newsletter-border-color: rgba(0, 208, 211, 0.8);
  --newsletter-button-background-color: rgb(0, 208, 211);
  --newsletter-button-background-color--hover: rgb(2, 135, 137);
  --newsletter-button-text-color: #000;
  --newsletter-button-text-color--hover: #fff;
  --newsletter-brand-logo: url("../../../../thejourney.co/images/tj-logo-wide--light.svg");
  --newsletter-brand-logo-aspect-ratio: 6.72;
  --newsletter-error-color: #ff0000;
  --newsletter-heading-color: #FFF;
  --newsletter-text-color: rgba(255, 255, 255, 0.8);
  --table-font-size: var(--article-body-font-size--smaller);
  --table-header-wrapped-font-size: var(--article-body-font-size--smallest);
  --table-line-height: 1.2;
  --table-border-color: #ccc;
  --testimonial-color: #2e648f;
  --testimonial-color--even: #d72439;
  --testimonial-background-color: rgba(46, 100, 143, 0.15);
  --testimonial-background-color--even: rgba(215, 36, 57, 0.15);
  --testimonial-badge-background: #2e648f;
  --testimonial-badge-background--even: #d72439;
  --testimonial-badge-border-radius: 5px;
  --testimonial-badge-font-size: 16px;
  --testimonial-badge-padding-bottom: 10px;
  --testimonial-badge-padding-sides: 30px;
  --testimonial-badge-padding-top: 8px;
  --testimonial-badge-text-color: #ffffff;
  --testimonial-badge-text-color--even: #ffffff;
  --testimonial-body-font-size: 16px;
  --testimonial-border-color: rgba(46, 100, 143, 0.5);
  --testimonial-border-color--even: rgba(215, 36, 57, 0.5);
  --testimonial-border-radius: 10px;
  --testimonial-border-width: 3px;
  --testimonial-link-color: #2e648f;
  --testimonial-link-color--even: #d72439;
  --testimonial-padding-bottom: 25px;
  --testimonial-padding-sides: 30px;
  --testimonial-padding-top: 20px;
  --ul-li-icon: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='12' width='12'><circle cx='6' cy='6' r='5' fill='none' stroke='lightgray' stroke-width='1'/></svg>");
}